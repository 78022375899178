import { Drawer } from 'antd';
import cn from 'classnames';
import { find, sortBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import AddressAutoComplete from '../../../../../components/sdk-address-autocomplete/AddressAutoComplete';
import { useViewport } from '../../../../../hooks/useViewport';
import CustomerEditor from '../../../../setup/setup-customers/customer-editor';
import CustomerContactsEditor from '../../../../setup/setup-customers/customer-editor/customer-contacts-editor';
import ProjectEditor from '../../../../setup/setup-projects/project-editor';
import ColorPicker from '../../color-picker';
import DateTimePicker from '../../date-time-picker/DateTimePicker';
import InputField from '../../InputField';
import { Translate } from '../../localization/Translate';
import { useTranslateMessage } from '../../localization/useTranslateMessage';
import OrderMap from '../../order-map/OrderMap';
import SelectField from '../../SelectField';
import { useOrderContext } from '../../useOrderContext';
import { MODES } from '../OrderForm';
import AddItem from './AddItem';
import GeoCodeMarker from './GeoCodeMarker';
import OverriddenDefaultsExplanations from './OverriddenDefaultsExplanations';
import { useUserContext } from '../../user-context/UserContextProvider';
import { usePermissions } from '../../usePermissions';
import { useParams } from 'react-router';
import TextAreaField from '../../TextAreaField';
import generateEnableTicketingFlag from '../../../../../util/other/generate-enable-ticketing-flag';
import EditItem from './EditItem';
import MultiDateTimePiker from '../../multiple-date-time-picker';
import CustomPhoneInput from '../../phone-input/PhoneInput';
import SelectCountry from '../../../../setup/components/select-country';
import { Checkbox } from '../../../../../components/checkbox';
import InputNumberBlur from '../../../../setup/components/input-number-blur';
import useSetup from '../../../../setup/useSetup';
import SelectDispatchLocation from '../../../../setup/components/select-dispatch-location';

const Styled = styled.div``;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 8px;
  }
`;

const creditHoldStatus = {
  crn: 'CREDIT HOLD',
  defaultValue: false,
  name: 'CREDIT HOLD',
  status: 'ACTIVE',
};

const LINKED_ORDER_FIELDS = ['startDateTime', 'supplierStatusRef', 'locationRef'];

const Fields = props => {
  const {
    //WARNING, handleChange will use a cloneDeep to update the form
    form: { form, handleChange, purchaseOrderRequired, setPurchaseOrderRequired },
    masterData: { masterData, masterDataBusy } = {},
    order: { rawOrder, readOnly, isTicketed, productsTouched, setProductsTouched },
    lists,
    mode,
    saveOrderBusy,
    supplierParty: { timeZone },
    orderRequestRef,
    orderRequestMetaData,
  } = useOrderContext();

  const { entityRef } = useParams();

  const { hasPermissionNoAdmin } = useUserContext();

  const [newCustomer, setNewCustomer] = useState(null);
  const [newProject, setNewProject] = useState(null);
  const [creditCode, setCreditCode] = useState(null);
  const [activeCreditCode, setActiveCreditCode] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [interimContact, setInterimContact] = useState(null);
  const [manualPhoneNumber, setManualPhoneNumber] = useState(false);
  const [isCOD, setIsCOD] = useState(false);

  const customerFieldRef = useRef();
  const projectFieldRef = useRef();
  const locationFieldRef = useRef();
  const startDateFieldRef = useRef();
  const contactFieldRef = useRef();

  const { companyLicenses, getCompanyLicenses } = useSetup();

  const { has } = usePermissions();

  const creditCodeList = useMemo(() => {
    return lists?.creditCodes?.list ?? [];
  }, [lists]);

  const customerList = useMemo(() => {
    // const minimumList = [addItem('Customer')];
    if (!lists?.customers?.list) return [];

    const list = lists?.customers?.list;

    return sortBy(newCustomer ? list?.concat?.(newCustomer) : list, 'name');

    //NEVER PUT form ON THIS USEEFFECT DEPENCENCY ARRAY
    //IT WILL TRIGGER A INFINTE LOOP IF THE CUSTOMER/PROJECT HAS A CREDIT CODE
  }, [lists, newCustomer]);

  const projectList = useMemo(() => {
    const list = lists?.projects?.list;
    if (!list) return [];
    return newProject ? list?.concat?.(newProject) : list;
  }, [lists?.projects?.list, newProject]);

  const handleZoneChargesChange = e => {
    const { id, checked } = e.target;
    handleChange(id, checked);
  };

  useEffect(() => {
    getCompanyLicenses(entityRef).then();
  }, [getCompanyLicenses, entityRef]);

  const hasLocationZonesLicense = useMemo(() => {
    return !!companyLicenses.find(l => l.id === 'rmx-location-zones');
  }, [companyLicenses]);

  useEffect(() => {
    if (mode.id === MODES.COPY.id) {
      startDateFieldRef?.current?.focus?.();
    } else {
      customerFieldRef?.current?.focus?.();
    }
  }, [mode.id]);

  useEffect(() => {
    if (creditCodeList?.length && customerList?.length && form?.customerRef) {
      const creditCodeRef = customerList.find(c => c.crn === form.customerRef)?.creditCodeRef;

      if (creditCodeRef) {
        const cc = creditCodeList.find(cc => cc.crn === creditCodeRef);
        if (cc) {
          setCreditCode(creditCode ? { ...creditCode, customer: cc } : { customer: cc });
        }
      } else {
        setCreditCode(creditCode ? { ...creditCode, customer: undefined } : null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCodeList, customerList, form?.customerRef]);

  useEffect(() => {
    if (customerList?.length && projectList?.length && form?.customerRef) {
      const requirePurchaseOrder = customerList.find(c => c.crn === form.customerRef)?.requirePurchaseOrder;
      const projectRequiredPO = projectList.find(p => p.crn === form.projectRef)?.requirePurchaseOrder;
      let isRequire = false;

      if (form.projectRef === 'NONE') {
        isRequire = requirePurchaseOrder;
      } else {
        isRequire = projectRequiredPO;
      }

      if (typeof isRequire !== 'undefined') {
        setPurchaseOrderRequired(isRequire);
      }
    }
  }, [form?.customerRef, form.projectRef, customerList]);

  useEffect(() => {
    if (creditCodeList?.length && projectList?.length && form?.projectRef) {
      const project = projectList.find(p => p.crn === form.projectRef);
      if (project && project.id !== 'NONE' && project.projLevelCredit && project.creditCodeRef) {
        const cc = creditCodeList.find(cc => cc.crn === project.creditCodeRef);
        if (cc) {
          setCreditCode(creditCode ? { ...creditCode, project: cc } : { project: cc });
        }
      } else if (creditCode) {
        setCreditCode({ ...creditCode, project: undefined });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCodeList, projectList, form?.projectRef]);

  useEffect(() => {
    const data = {
      creditCode: creditCode?.project ? creditCode.project : creditCode?.customer,
    };

    if (data.creditCode) {
      data.level = creditCode?.project ? 'project' : 'customer';

      data.paymentMethods =
        data.creditCode.limitPaymentMethods && data.creditCode.paymentMethods?.length
          ? masterData?.paymentMethods?.filter(pm => data.creditCode.paymentMethods.includes(pm.id))
          : undefined;

      if (data.creditCode.hasCreditHold) {
        const hasOverridePermission = has('overrideCreditHold');
        data.supplierStatusCodes =
          data.creditCode.isOverrideAllowed && hasOverridePermission
            ? [creditHoldStatus].concat(
                masterData?.supplierStatusCodes?.filter(c => c.crn !== creditHoldStatus.crn) ?? []
              )
            : [creditHoldStatus].concat(
                masterData?.supplierStatusCodes?.filter(c => !generateEnableTicketingFlag(c)) ?? []
              );
      }
    }
    setActiveCreditCode(data);
  }, [masterData, creditCode, has]);

  useEffect(() => {
    let hasHold = false;

    // Update selected status code (if new order)
    if (activeCreditCode?.creditCode?.hasCreditHold) {
      const existingOrder = !!form?.crn;

      if (!existingOrder) {
        hasHold = !activeCreditCode.creditCode.isOverrideAllowed;
        handleChange('supplierStatusRef', creditHoldStatus.crn);
      }
    }

    handleChange('hasHold', hasHold);

    // Update selected payment method
    if (form?.paymentMethodRef && activeCreditCode?.paymentMethods?.length) {
      if (!activeCreditCode.paymentMethods.find(pm => pm.crn === form.paymentMethodRef)) {
        handleChange('paymentMethodRef', activeCreditCode.paymentMethods[0].crn);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCreditCode, form?.crn, handleChange]);

  useEffect(() => {
    if (form?.paymentMethodRef) {
      const paymentMethod = masterData?.paymentMethods?.find(p => p.crn === form?.paymentMethodRef);
      setIsCOD(paymentMethod?.isCOD ?? false);
    }
  }, [form?.paymentMethodRef, masterData?.paymentMethods]);

  const onSaveCustomer = useCallback(
    response => {
      handleChange('customerRef', response.crn);
      setNewCustomer(response);
    },
    [handleChange]
  );

  //Load all contacts of customer into the contacts state
  //if interimContact exists its because the order has a contact that the customer no longer has
  // so it must be added to the contacts array
  useEffect(() => {
    if (lists?.customers?.list && form?.customerRef) {
      const customer = lists?.customers?.list.find(customer => customer.crn === form?.customerRef);
      const mappedContacts =
        customer?.contacts?.map((customer, index) => ({
          ...customer,
          selectId: index.toString(),
        })) || [];

      if (interimContact) {
        mappedContacts.push(interimContact);
      }
      setContacts(mappedContacts);
    }
  }, [form?.customerRef, setContacts, lists?.customers?.list, interimContact]);

  //In edit view, we neet to get the contact in the existing order and find it in the customer contacts
  //with the temporaly id (selectId).
  //if the contact does not exists in the customer contacts this contact will be stored in interimContact
  //if the contact exists in the customer contacts we will just make that contact the selected in the select
  useEffect(() => {
    if (form?.contact && !form?.contact?.selectId && form?.customerRef && contacts !== null) {
      const contact =
        contacts.find(contact => form.contact.contactRef === contact.id) ||
        contacts.find(
          contact =>
            form.contact.firstName === contact.firstName &&
            form.contact.lastName === contact.lastName &&
            form.contact.phoneNumber === contact.phone &&
            form.contact.email === contact.email
        );

      if (contact) {
        handleChange('contact', contact);
      } else {
        const newContact = { ...form?.contact, selectId: '-1' };
        handleChange('contact', newContact);
        setInterimContact(newContact);
      }
    }
  }, [form, contacts, handleChange, setInterimContact]);

  const onSaveCustomerContacts = useCallback(
    response => {
      const mappedContacts = response.map((customer, index) => ({ ...customer, selectId: index.toString() }));

      if (mappedContacts?.length) {
        const contact = mappedContacts[mappedContacts.length - 1];
        handleChange('contact', contact);
        if (contact?.phone && !manualPhoneNumber) {
          handleChange('phoneNumber', contact.phone);
        }
      } else if (interimContact) {
        handleChange('contact', interimContact);
      } else {
        handleChange('contact', null);
      }

      if (interimContact) {
        mappedContacts.push(interimContact);
      }
      setContacts(mappedContacts);
    },
    [handleChange, interimContact, manualPhoneNumber]
  );

  const handleContactChange = useCallback(
    (fieldName, value) => {
      const contact = contacts.find(contact => contact.selectId === value);

      handleChange(fieldName, contact);
      if (contact?.phone && !manualPhoneNumber) {
        handleChange('phoneNumber', contact.phone);
      }
      if (!contact && !manualPhoneNumber) {
        handleChange('phoneNumber', null);
      }
    },
    [handleChange, contacts, manualPhoneNumber]
  );

  const handlePhoneNumberChange = useCallback(
    (fieldName, value) => {
      setManualPhoneNumber(value !== '');
      handleChange(fieldName, value !== '1' ? value : null);
    },
    [handleChange]
  );

  const onSaveProject = useCallback(
    response => {
      handleChange('projectRef', response.crn);
      setNewProject(response);
    },
    [handleChange]
  );

  const [customerEditorIsOpen, setCustomerEditorIsOpen] = useState(false);
  const toggleCustomerEditor = useCallback(() => {
    setCustomerEditorIsOpen(s => !s);
  }, []);

  const [contactEditorIsOpen, setContactEditorIsOpen] = useState(false);
  const toggleContactEditor = useCallback(() => {
    setContactEditorIsOpen(s => !s);
  }, []);

  const [projectEditorIsOpen, setProjectEditorIsOpen] = useState(false);
  const [overriddenCustomer, setOverriddenCustomer] = useState(form?.overriddenCustomer);

  const toggleProjectEditor = useCallback(() => {
    setProjectEditorIsOpen(s => !s);
  }, []);

  const [customerNameEditorIsOpen, setCustomerNameEditorIsOpen] = useState(false);
  const toggleCustomerNameEditor = useCallback(() => {
    setCustomerNameEditorIsOpen(s => !s);
  }, []);

  const handleShippingMethodChange = useCallback(
    (fieldName, value) => {
      handleChange(fieldName, value);
      const pickupRef = find(masterData?.shippingMethods, { name: 'PICKUP' })?.crn;
      const deliveryRef = find(masterData?.shippingMethods, { name: 'DELIVERY' })?.crn;

      const addressRef = find(masterData?.taxRateRef, { name: 'Delivery Address' })?.crn;
      const locationRef = find(masterData?.taxRateRef, { name: 'Plant Address' })?.crn;

      if (form?.taxRateRef !== 'exempt' && form?.taxRateRef !== 'dispatchLocation') {
        if (value === pickupRef) {
          handleChange('taxRateRef', locationRef);
        } else if (value === deliveryRef) {
          handleChange('taxRateRef', addressRef);
        }
      }
    },
    [handleChange, masterData?.shippingMethods, masterData?.taxRateRef, form?.taxRateRef]
  );

  const handleoverriddenCustomer = useCallback(
    value => {
      handleChange('overriddenCustomer', value);
      setOverriddenCustomer(value);
    },
    [handleChange, setOverriddenCustomer]
  );

  const handleCustomerChange = useCallback(
    (fieldName, value) => {
      if (!productsTouched) {
        setProductsTouched(true);
      }
      handleChange(fieldName, value);
      handleChange('projectRef', null);
      handleChange('contact', null);
      setNewProject(null);
      handleChange('overriddenCustomer', null);
      setOverriddenCustomer(null);
    },
    [handleChange, productsTouched, setProductsTouched]
  );

  const handleTaxRateChange = useCallback(
    (fieldName, value) => {
      handleChange(fieldName, value);
      if (value !== 'exempt' && form?.taxExemptReasonRef) {
        handleChange('taxExemptReasonRef', null);
      }
      if (value === 'dispatchLocation' && form?.locationRef) {
        const location = find(lists?.locations?.list, { crn: form?.locationRef });
        handleChange('dispatchLocationRef', location?.dispatchLocationRef);
      }
    },
    [handleChange, lists?.locations?.list, form?.locationRef, form?.taxExemptReasonRef]
  );

  const customerListWithOverrideName = useMemo(() => {
    if (!overriddenCustomer) return customerList;

    return customerList?.map(customer => {
      if (customer.crn === overriddenCustomer.crn || customer.crn === overriddenCustomer.entityRef) {
        return {
          crn: overriddenCustomer.entityRef || overriddenCustomer.crn,
          ...overriddenCustomer,
        };
      }
      return customer;
    });
  }, [overriddenCustomer, customerList]);

  const handleProjectChange = useCallback(
    (fieldName, value) => {
      if (!productsTouched) {
        setProductsTouched(true);
      }
      handleChange(fieldName, value);
      const taxExemptReasonRef =
        value === 'NONE'
          ? customerListWithOverrideName?.find(c => c.crn === form?.customerRef)?.taxExemptReasonRef
          : projectList?.find(p => p.crn === value)?.taxExemptReasonRef;
      handleChange('taxExemptReasonRef', taxExemptReasonRef);
    },
    [handleChange, projectList, form?.customerRef, customerListWithOverrideName, productsTouched, setProductsTouched]
  );

  const handlePaymentMethod = useCallback(
    (fieldName, value) => {
      handleChange(fieldName, value);
      //We should not update the customer override if the order is ticketed
      if (!readOnly && !isTicketed) {
        handleChange('overriddenCustomer', null);
        setOverriddenCustomer(null);
      }
    },
    [readOnly, handleChange, isTicketed]
  );

  const viewport = useViewport();
  const [showMap, setShowMap] = useState(false);

  const location = useMemo(
    () => find(lists?.locations?.list, { crn: form?.locationRef }),
    [form?.locationRef, lists?.locations?.list]
  );

  const handleOverrideLinkedOrderChange = useCallback(
    (field, value) => {
      if (!productsTouched && field === 'locationRef') {
        setProductsTouched(true);
      }
      const existingValue = form[field];
      handleChange(field, value);
      const location = find(lists?.locations?.list, { crn: value });
      if (location?.taxBasis === 'dispatchLocation' && form?.taxRateRef !== 'exempt') {
        handleChange('dispatchLocationRef', location?.dispatchLocationRef);
      }

      if (LINKED_ORDER_FIELDS.includes(field)) {
        const linkedOrders = form?.linkedOrders?.map(order => {
          if (order[field] !== existingValue) {
            return order;
          }
          return {
            ...order,
            [field]: value,
          };
        });

        handleChange('linkedOrders', linkedOrders);
      }
    },
    [handleChange, form, lists?.locations?.list, productsTouched, setProductsTouched]
  );

  const showProjectNotes = mode.id === MODES.CREATE.id || mode.id === MODES.COPY.id;
  const hasRequiredData = useMemo(() => {
    return form?.locationRef && form?.projectRef && !form?.hasHold;
  }, [form]);

  const { start } = useOrderContext();
  const isReplenishment = start?.startedOrder?.license?.hasFullReplenishment;
  const requiredAddress = useMemo(() => {
    return form?.taxRateRef === 'address';
  }, [form?.taxRateRef]);

  const isCustomerChanged = useMemo(
    () => form?.customerRef !== rawOrder?.customerParty?.entityRef,
    [form?.customerRef, rawOrder?.customerParty?.entityRef]
  );

  const orderRequestLocationsError = useMemo(() => {
    if (!orderRequestRef || lists?.locations?.list?.length || lists.locations.busy) return null;
    if (!orderRequestMetaData?.length || !orderRequestMetaData?.[0]?.error)
      return 'Failed to get locations for this order request';
    return orderRequestMetaData[0].error;
  }, [orderRequestRef, lists?.locations, orderRequestMetaData]);

  const translateMessage = useTranslateMessage();

  const taxBasis = useMemo(() => {
    return masterData?.taxRateRef?.map(rate => ({
      name: translateMessage({ stringId: `taxRate__${rate.crn}`, defaultMessage: rate.name }),
      crn: rate.crn,
    }));
  }, [masterData, translateMessage]);

  const taxExemptReasonInterimValue = useMemo(() => {
    return lists.taxExemptReasons.list.find(reason => reason.value === form?.taxExemptReasonRef);
  }, [form?.taxExemptReasonRef, lists.taxExemptReasons.list]);

  return (
    <Styled className={cn('general-fields')}>
      <div className="row-1">
        <SelectField
          required
          onChange={handleCustomerChange}
          list={customerListWithOverrideName}
          listBusy={lists?.customers?.busy}
          value={form?.customerRef}
          fieldName="customerRef"
          fieldLabel={translateMessage({ stringId: 'customer', defaultMessage: 'Customer' })}
          valuePath="crn"
          displayPath={['id', 'name']}
          innerRef={customerFieldRef}
          selectOnTabPress
          disabled={saveOrderBusy}
          interimValue={{ fieldValue: rawOrder?.customerParty, valuePath: 'entityRef' }}
          readOnly={readOnly || isTicketed}
          toggleEditor={toggleCustomerEditor}
          addItem={
            !hasPermissionNoAdmin('authorities.enable_rmx-dispatch_orders_disable-create-order-customers-projects') && (
              <AddItem
                type="Customer"
                Editor={CustomerEditor}
                onSave={onSaveCustomer}
                list={lists?.customers?.list}
                editorIsOpen={customerEditorIsOpen}
                toggleEditor={toggleCustomerEditor}
                selfRef={customerFieldRef}
                nextRef={projectFieldRef}
                disabled={saveOrderBusy}
              />
            )
          }
          editItem={
            isCOD && (
              <EditItem
                editorIsOpen={customerNameEditorIsOpen}
                toggleEditor={toggleCustomerNameEditor}
                list={customerListWithOverrideName}
                disabled={saveOrderBusy}
                onSave={value => handleoverriddenCustomer(value)}
                itemCrn={form?.customerRef}
              />
            )
          }
        />

        <SelectField
          required
          onChange={handleProjectChange}
          list={projectList}
          listBusy={lists.projects.busy}
          value={form?.projectRef}
          fieldName="projectRef"
          fieldLabel={translateMessage({ stringId: 'project', defaultMessage: 'Project' })}
          valuePath="crn"
          displayPath={['id', 'name']}
          innerRef={projectFieldRef}
          selectFirstWhenOne
          selectOnTabPress
          defaultRefToSelect="NONE"
          disabled={!form?.customerRef || saveOrderBusy}
          interimValue={isCustomerChanged ? null : { fieldValue: rawOrder?.project }}
          readOnly={readOnly || isTicketed}
          toggleEditor={toggleProjectEditor}
          addItem={
            !hasPermissionNoAdmin('authorities.enable_rmx-dispatch_orders_disable-create-order-customers-projects') && (
              <AddItem
                type="Project"
                Editor={ProjectEditor}
                onSave={onSaveProject}
                list={lists?.projects?.list}
                editorIsOpen={projectEditorIsOpen}
                toggleEditor={toggleProjectEditor}
                selfRef={projectFieldRef}
                nextRef={locationFieldRef}
                disabled={!form?.customerRef || saveOrderBusy}
                dependencyRef={form?.customerRef}
              />
            )
          }
        />

        <SelectField
          fieldLabel={translateMessage({ stringId: 'scheduledLocation', defaultMessage: 'Scheduled Location' })}
          fieldName="locationRef"
          list={lists.locations.list}
          listBusy={lists.locations.busy}
          onChange={handleOverrideLinkedOrderChange}
          required
          value={form?.locationRef}
          displayPath={['id', 'name']}
          innerRef={locationFieldRef}
          selectOnTabPress
          valuePath="crn"
          disabled={!form?.customerRef || saveOrderBusy}
          interimValue={{ fieldValue: rawOrder?.location }}
          readOnly={readOnly || isTicketed}
          error={orderRequestLocationsError}
        />
      </div>
      <div className="row-2">
        <div className="field-column a">
          <SelectField
            fieldLabel={translateMessage({ stringId: 'shippingMethod', defaultMessage: 'Shipping Method' })}
            fieldName="shippingMethodRef"
            list={masterData?.shippingMethods}
            listBusy={masterDataBusy}
            onChange={handleShippingMethodChange}
            value={form?.shippingMethodRef}
            valuePath="crn"
            displayPath="name"
            disabled={!hasRequiredData || saveOrderBusy}
            interimValue={{ fieldValue: rawOrder?.shippingMethod }}
            selectOnTabPress
            readOnly={readOnly}
          />

          <SelectField
            fieldLabel={translateMessage({ stringId: 'status', defaultMessage: 'Status' })}
            fieldName="supplierStatusRef"
            list={activeCreditCode?.supplierStatusCodes || masterData?.supplierStatusCodes}
            listBusy={masterDataBusy}
            onChange={handleOverrideLinkedOrderChange}
            required
            value={form?.supplierStatusRef}
            valuePath="crn"
            displayPath="name"
            disabled={!hasRequiredData || saveOrderBusy}
            interimValue={{ fieldValue: rawOrder?.supplierStatus }}
            selectOnTabPress
            readOnly={readOnly}
          />

          <SelectField
            required
            fieldLabel={translateMessage({ stringId: 'paymentMethod', defaultMessage: 'Payment Method' })}
            fieldName="paymentMethodRef"
            list={activeCreditCode?.paymentMethods || masterData?.paymentMethods}
            listBusy={masterDataBusy}
            onChange={handlePaymentMethod}
            value={form?.paymentMethodRef}
            valuePath="crn"
            displayPath="name"
            disabled={!hasRequiredData || saveOrderBusy}
            interimValue={{ fieldValue: rawOrder?.paymentMethod }}
            selectOnTabPress
            readOnly={readOnly}
          />

          <SelectField
            fieldLabel={translateMessage({ stringId: 'workType', defaultMessage: 'Work Type' })}
            fieldName="workTypeRef"
            displayPath="name"
            list={masterData?.workTypes}
            listBusy={masterDataBusy}
            onChange={handleChange}
            value={form?.workTypeRef}
            valuePath="crn"
            disabled={!hasRequiredData || saveOrderBusy}
            interimValue={{ fieldValue: rawOrder?.workType }}
            selectOnTabPress
            readOnly={readOnly}
          />

          <SelectField
            fieldLabel={translateMessage({ stringId: 'salesPerson', defaultMessage: 'Salesperson' })}
            fieldName="salesPersonRef"
            list={masterData?.salesPersonRef}
            listBusy={masterDataBusy}
            onChange={handleChange}
            value={form?.salesPersonRef}
            valuePath="crn"
            displayPath="name"
            disabled={!hasRequiredData || saveOrderBusy}
            interimValue={{ fieldValue: rawOrder?.salesPerson, valuePath: 'profileRef' }}
            selectOnTabPress
            readOnly={readOnly}
          />
          <InputField
            onChange={handleChange}
            value={form?.purchaseOrder}
            fieldName="purchaseOrder"
            busy={masterDataBusy}
            fieldLabel={translateMessage({ stringId: 'purchaseOrder', defaultMessage: 'Purchase Order' })}
            disabled={!hasRequiredData || saveOrderBusy}
            readOnly={readOnly}
            required={purchaseOrderRequired}
          />
        </div>
        <div className="field-column project-info">
          <div style={{ display: 'flex', gridGap: '15px' }}>
            {isReplenishment ? (
              <InputField
                onChange={handleChange}
                value={form?.address?.name}
                fieldName="address.name"
                fieldLabel={translateMessage({ stringId: 'addressName', defaultMessage: 'Address Name' })}
                disabled={!hasRequiredData || saveOrderBusy}
                readOnly={readOnly}
              />
            ) : null}
            {readOnly ? (
              <div style={{ width: '100%' }}>
                <InputField
                  required={requiredAddress}
                  onChange={handleChange}
                  value={form?.address?.address1}
                  fieldName="address.address1"
                  fieldLabel={translateMessage({ stringId: 'address1', defaultMessage: 'Address 1' })}
                  style={{ width: isReplenishment ? '60%' : '100%' }}
                  disabled={!hasRequiredData || saveOrderBusy}
                  addonAfter={
                    !hasRequiredData || !form?.startDateTime || saveOrderBusy ? undefined : (
                      <GeoCodeMarker
                        onClick={() => setShowMap(true)}
                        geocoded={!!form?.address?.coordinates?.longitude}
                      />
                    )
                  }
                  readOnly={readOnly}
                />
                <InputField
                  onChange={handleChange}
                  value={form?.address?.address2}
                  fieldName="address.address2"
                  fieldLabel={translateMessage({ stringId: 'address2', defaultMessage: 'Address 2' })}
                  style={{ width: isReplenishment ? '60%' : '100%' }}
                  disabled={!hasRequiredData || saveOrderBusy}
                  readOnly={readOnly}
                />
              </div>
            ) : (
              <div style={{ width: '100%' }}>
                <AddressAutoComplete
                  id="address1"
                  required={requiredAddress}
                  lat={location?.locationAddress?.coordinates?.latitude}
                  lng={location?.locationAddress?.coordinates?.longitude}
                  onChange={handleChange}
                  value={form?.address?.address1}
                  fieldLabel={translateMessage({ stringId: 'address1', defaultMessage: 'Address 1' })}
                  formAddress={form?.address}
                  style={{ width: isReplenishment ? '60%' : '100%' }}
                  disabled={!hasRequiredData || saveOrderBusy}
                  addonAfter={
                    !hasRequiredData || saveOrderBusy ? undefined : (
                      <GeoCodeMarker
                        onClick={() => setShowMap(true)}
                        geocoded={!!form?.address?.coordinates?.longitude}
                      />
                    )
                  }
                />
                <InputField
                  onChange={handleChange}
                  value={form?.address?.address2}
                  fieldName="address.address2"
                  fieldLabel={translateMessage({ stringId: 'address2', defaultMessage: 'Address 2' })}
                  style={{ width: isReplenishment ? '60%' : '100%', marginTop: '5px' }}
                  disabled={!hasRequiredData || saveOrderBusy}
                />
              </div>
            )}
          </div>
          <StyledDrawer
            width={viewport.width}
            placement="right"
            closable
            onClose={() => setShowMap(false)}
            visible={showMap}
            title="Order Map"
            destroyOnClose
          >
            <OrderMap
              readOnly={rawOrder?.originatorSystemType !== 'CONNEX'}
              forceDestinationUpdate={rawOrder?.originatorSystemType === 'CONNEX'}
              onDone={() => setShowMap(false)}
            />
          </StyledDrawer>
          <div style={{ display: 'flex', gridGap: '15px' }}>
            <div style={{ width: '50%' }}>
              <InputField
                required={requiredAddress}
                onChange={handleChange}
                value={form?.address?.city}
                fieldName="address.city"
                fieldLabel={translateMessage({ stringId: 'city', defaultMessage: 'City' })}
                disabled={!hasRequiredData || saveOrderBusy}
                readOnly={readOnly}
              />
            </div>
            <div style={{ width: '50%' }}>
              <InputField
                required={requiredAddress}
                onChange={handleChange}
                value={form?.address?.countrySubDivision}
                fieldName="address.countrySubDivision"
                fieldLabel={translateMessage({ stringId: 'state', defaultMessage: 'State / Province / Region' })}
                disabled={!hasRequiredData || saveOrderBusy}
                readOnly={readOnly}
              />
            </div>
          </div>
          <div style={{ display: 'flex', gridGap: '15px' }}>
            <InputField
              required={requiredAddress}
              onChange={handleChange}
              value={form?.address?.postalCode}
              fieldName="address.postalCode"
              fieldLabel={translateMessage({ stringId: 'postalCode', defaultMessage: 'Postal Code' })}
              disabled={!hasRequiredData || saveOrderBusy}
              readOnly={readOnly}
            />
            <SelectCountry
              id="address.countryCode"
              label="Country"
              onChange={handleChange}
              value={form?.address?.countryCode}
              disabled={!hasRequiredData || saveOrderBusy}
              readOnly={readOnly}
              required={false}
              orderScreen={true}
            />
          </div>
          <SelectField
            fieldLabel={translateMessage({ stringId: 'contact', defaultMessage: 'Contact' })}
            fieldName="contact"
            list={contacts}
            listBusy={lists?.customers?.busy}
            onChange={handleContactChange}
            value={form?.contact?.selectId}
            valuePath="selectId"
            displayPath={['firstName', 'lastName']}
            innerRef={contactFieldRef}
            disabled={!hasRequiredData || saveOrderBusy || !form?.customerRef || !contacts?.length}
            selectOnTabPress
            readOnly={readOnly}
            toggleEditor={toggleCustomerEditor}
            displaySpacing=" "
            addItem={
              !hasPermissionNoAdmin('authorities.enable_rmx-dispatch_orders_disable-create-order-contacts') && (
                <AddItem
                  type="Customer Contacts"
                  Editor={CustomerContactsEditor}
                  onSave={onSaveCustomerContacts}
                  editorIsOpen={contactEditorIsOpen}
                  toggleEditor={toggleContactEditor}
                  selfRef={contactFieldRef}
                  disabled={!hasRequiredData || saveOrderBusy || !form?.customerRef}
                  itemCrn={form?.customerRef}
                />
              )
            }
          />
          <CustomPhoneInput
            onChange={handlePhoneNumberChange}
            value={form?.phoneNumber}
            fieldName="phoneNumber"
            fieldLabel={translateMessage({ stringId: 'phoneNumber', defaultMessage: 'Phone Number' })}
            disabled={!hasRequiredData || saveOrderBusy}
            readOnly={readOnly}
          />
          <div style={{ display: 'flex', gridGap: '15px', verticalAlign: 'middle' }}>
            <div style={{ maxWidth: '100px' }}>
              {hasLocationZonesLicense && (
                <InputNumberBlur
                  trim={true}
                  id="distance"
                  onChange={handleChange}
                  label={translateMessage({ stringId: 'distance', defaultMessage: 'Distance (Miles)' })}
                  value={form?.distance}
                  disabled={!hasRequiredData || saveOrderBusy}
                />
              )}
            </div>
            <div style={{ marginTop: '5%' }}>
              {hasLocationZonesLicense && (
                <Checkbox
                  id="excludeZoneCharges"
                  onChange={handleZoneChargesChange}
                  label="Exclude Zone Charges"
                  checked={form?.excludeZoneCharges}
                  disabled={!hasRequiredData || saveOrderBusy || isTicketed}
                />
              )}
            </div>
          </div>
        </div>
        <div className="field-column c">
          <div className={'field-row'}>
            <DateTimePicker
              id="startDateTime"
              required
              value={form?.startDateTime}
              onChange={handleOverrideLinkedOrderChange}
              timeZone={timeZone}
              disabled={!hasRequiredData || saveOrderBusy}
              innerRef={startDateFieldRef}
              readOnly={readOnly || isTicketed}
            />
            {mode === MODES.COPY && (
              <MultiDateTimePiker
                id="exdendedDatesList"
                className="dispatchMultipleDates"
                value={form?.exdendedDatesList}
                onChange={handleChange}
                disabled={!hasRequiredData || saveOrderBusy}
                showOrderStartDateTime={true}
                orderStartDateTime={form?.startDateTime}
                onChangeStartDateTime={handleOverrideLinkedOrderChange}
                timeZone={timeZone}
              />
            )}

            <ColorPicker
              fieldLabel={translateMessage({ stringId: 'dispatchColor', defaultMessage: 'Dispatch Color' })}
              fieldName="dispatchBackgroundColor"
              onChange={handleChange}
              value={form?.dispatchBackgroundColor || '#FFFF00'}
              disabled={!hasRequiredData || saveOrderBusy}
              readOnly={readOnly}
            />
          </div>
          <div className={'tax-section'}>
            <SelectField
              fieldLabel={translateMessage({ stringId: 'vehicleType', defaultMessage: 'Vehicle Type' })}
              fieldName="vehicleTypeRef"
              list={masterData?.vehicleTypes}
              listBusy={masterDataBusy}
              onChange={handleChange}
              value={form?.vehicleTypeRef}
              valuePath="crn"
              displayPath="name"
              disabled={!hasRequiredData || saveOrderBusy}
              interimValue={{ fieldValue: rawOrder?.vehicleType }}
              selectOnTabPress
              readOnly={readOnly}
            />

            <SelectField
              fieldLabel={translateMessage({ stringId: 'salesTaxBasis', defaultMessage: 'Sales Tax Basis' })}
              fieldName="taxRateRef"
              list={taxBasis}
              listBusy={masterDataBusy}
              onChange={handleTaxRateChange}
              value={form?.taxRateRef}
              valuePath="crn"
              displayPath="name"
              disabled={!hasRequiredData || saveOrderBusy}
              interimValue={{ fieldValue: rawOrder?.taxRate }}
              selectOnTabPress
              readOnly={readOnly || isTicketed}
            />
            {form?.taxRateRef === 'exempt' && lists?.taxExemptReasons?.required && (
              <div className={'select-tax-exempt'}>
                <SelectField
                  fieldName="taxExemptReasonRef"
                  fieldLabel={translateMessage({ stringId: 'taxExemptReason', defaultMessage: 'Tax-Exempt Reason' })}
                  value={form?.taxExemptReasonRef}
                  list={lists?.taxExemptReasons?.list}
                  valuePath="value"
                  displayPath="label"
                  onChange={handleChange}
                  listBusy={lists?.taxExemptReasons?.busy}
                  disabled={!hasRequiredData || saveOrderBusy}
                  interimValue={{ fieldValue: taxExemptReasonInterimValue }}
                  readOnly={readOnly || isTicketed}
                  required
                />
              </div>
            )}
            {form?.taxRateRef === 'dispatchLocation' && (
              <div className={'select-tax-exempt'}>
                <SelectDispatchLocation
                  id="dispatchLocationRef"
                  label="Dispatch Office"
                  value={form?.dispatchLocationRef || location?.dispatchLocationRef}
                  onChange={handleChange}
                  disabled={!hasRequiredData || saveOrderBusy}
                />
              </div>
            )}
          </div>

          <div style={{ gridArea: 'notes' }}>
            <div className="field-column">
              {showProjectNotes && (
                <InputField
                  onChange={handleChange}
                  value={form?.projectNote}
                  fieldName="projectNote"
                  fieldLabel={translateMessage({ stringId: 'note', defaultMessage: 'Note' })}
                  disabled={!hasRequiredData || saveOrderBusy}
                  readOnly={readOnly}
                />
              )}
              <TextAreaField
                onChange={handleChange}
                value={form?.driverInstructions}
                fieldName="driverInstructions"
                fieldLabel={translateMessage({
                  stringId: 'driverInstructions',
                  defaultMessage: 'Driver Instructions',
                })}
                disabled={!hasRequiredData || saveOrderBusy}
                readOnly={readOnly}
                rows={showProjectNotes ? 1 : 3}
                showCount={true}
                maxLength={750} // There are 6 u-link fields of 32 length each TODO(mason): maybe this should be configurable?
                style={{ height: '88px' }}
              />
              <TextAreaField
                onChange={handleChange}
                value={form?.billingNote}
                fieldName="billingNote"
                fieldLabel={translateMessage({ stringId: 'invoices.billingNote', defaultMessage: 'Billing Note' })}
                disabled={!hasRequiredData || saveOrderBusy}
                readOnly={readOnly}
                rows={showProjectNotes ? 1 : 2}
              />
            </div>
          </div>
        </div>
      </div>
      {activeCreditCode?.creditCode?.hasCreditHold && (
        <div className="creditHoldWarning">
          {!activeCreditCode.creditCode.isOverrideAllowed && (
            <div className="warningText">
              <Translate stringId={`${activeCreditCode.level}HardCreditHold`} />
            </div>
          )}
          {activeCreditCode.creditCode.isOverrideAllowed && (
            <div className="warningText soft">
              <Translate stringId={`${activeCreditCode.level}SoftCreditHold`} />
            </div>
          )}
        </div>
      )}
      <div className="row-3">
        <OverriddenDefaultsExplanations />
      </div>
    </Styled>
  );
};

export default Fields;
