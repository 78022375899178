import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Currency } from '../../localization/Currency';
import { Number } from '../../localization/Number';
import { Translate } from '../../localization/Translate';
import { Uom } from '../../localization/Uom';
import { useTranslateMessage } from '../../localization/useTranslateMessage';
import style from './style';
import { useParams } from 'react-router';
import { usePricing } from './usePricing';
import Spinner from '../../../../../components/spinner/Spinner';
import FlexColumn from '../../FlexColumn';
import CurrencyFormat from 'react-currency-format';
import Select from '../../../../../components/form/Select';
import { Row, Col, Drawer } from 'antd';
import Button from '../../../../../components/button/Button';
import useSetup from '../../../../setup/useSetup';
import Has from '../../Has';
import HasPricingPermission from '../HasPricingPermission';
import OrderCostBookPrices from './order-cost-book-prices';
import getUomAbbreviationOrDescription from '../../../../../util/uom';
import { useCallback } from 'react';
import { isNil } from 'lodash';
import roundedToCents from '../../../../../util/other/rounded-to-cents';
import { useTaxExemptReasons } from '../../../../../hooks/useTaxExemptReasons';

const { Option } = Select;

const taxRates = [
  {
    crn: 'address',
    name: 'Delivery Address',
  },
  {
    crn: 'customer',
    name: 'Customer Address',
  },
  {
    crn: 'project',
    name: 'Project Address',
  },
  {
    crn: 'location',
    name: 'Plant Address',
  },
  {
    crn: 'dispatchLocation',
    name: 'Dispatch Location Address',
  },
  {
    crn: 'exempt',
    name: 'Tax Exempt',
  },
];

const USD = ({ amount = 0, ...props }) => {
  return (
    <CurrencyFormat
      value={amount}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
      decimalScale={2}
      fixedDecimalScale={true}
      {...props}
    />
  );
};

const Pricing = ({ className, disableTabs, setDisableTabs }) => {
  const { orderId, tab } = useParams();
  const { getOrder, order, repriceOrder, pricing, pricingBusy, getOrderCostBooks, costBooks } = usePricing();
  const { taxExemptReasons, taxExemptReasonsBusy, taxExemptReasonRequired } = useTaxExemptReasons();
  const [taxExemptReason, setTaxExemptReason] = useState(null);
  const [taxRateRef, setTaxRateRef] = useState(null);
  const [jurisdictionRef, setJurisdictionRef] = useState(null);
  const [resolvedCostBookRef, setResolvedCostBookRef] = useState(null);
  const [costBookRef, setCostBookRef] = useState(null);
  const { saveSetupItem, setSetupItem } = useSetup('cost-book');
  const [isDirty, setIsDirty] = useState(false);
  const [overrideCostBook, setOverrideCostBook] = useState(null);
  const [costBook, setCostBook] = useState(null);
  const [resetCostBook, setResetCostBook] = useState(false);
  const [loadingCostBooks, setLoadingCostBooks] = useState(false);
  const drawerWidth = 600;

  const reprice = useCallback(
    costBookRef => {
      repriceOrder(orderId, {
        taxRateRef,
        jurisdictionRef,
        costBookRef,
        costBookOverride: !isNil(costBookRef),
        resetCostBook,
        taxExemptReasonRef: taxExemptReason,
      })
        .then(() => setResetCostBook(false))
        .catch(alert);
    },
    [jurisdictionRef, orderId, repriceOrder, resetCostBook, taxRateRef, taxExemptReason]
  );

  const onDrawerSave = useCallback(() => {
    setSetupItem(costBook);

    saveSetupItem(costBook).then(savedItem => {
      setCostBookRef(savedItem?.crn);
      setCostBook(savedItem);
      if (order?.crn) {
        getOrderCostBooks(order?.crn).then(() => {
          onCloseDrawer();
          reprice(savedItem?.crn);
        });
      }
    });
  }, [costBook, getOrderCostBooks, order?.crn, reprice, saveSetupItem, setSetupItem]);

  const onCloseDrawer = () => {
    setOverrideCostBook(null);
  };

  const handleGetCostBooks = useCallback(async () => {
    try {
      setLoadingCostBooks(true);
      await getOrderCostBooks(order?.crn);
    } catch (error) {
      alert(error.message);
    } finally {
      setLoadingCostBooks(false);
    }
  }, [getOrderCostBooks, order]);

  useEffect(() => {
    if (!order?.crn) return;
    handleGetCostBooks();
  }, [handleGetCostBooks, order]);

  useEffect(() => {
    setIsDirty(
      pricing?.priceSummary?.taxRate?.taxRateRef !== jurisdictionRef ||
        order?.taxRate?.taxRateRef !== taxRateRef ||
        (pricing?.supplierParty?.costBookRef || null) !== costBookRef ||
        resetCostBook === true ||
        taxExemptReason !== order?.taxExemptReasonRef
    );
  }, [jurisdictionRef, taxRateRef, costBookRef, resetCostBook, order, pricing, taxExemptReason]);

  useEffect(() => {
    orderId && tab === 'pricing' && getOrder(orderId);
  }, [orderId, tab, getOrder]);

  useEffect(() => {
    setCostBookRef(pricing?.supplierParty?.costBookRef || null);
    setResolvedCostBookRef(pricing?.supplierParty?.resolvedCostBookRef);
  }, [pricing]);

  useEffect(() => {
    pricing && setTaxRateRef(pricing?.taxRate?.taxRateRef);
    pricing && setJurisdictionRef(pricing?.priceSummary?.taxRate?.taxRateRef);
  }, [pricing]);

  useEffect(() => {
    setTaxExemptReason(order?.taxExemptReasonRef);
  }, [order]);

  const onChange = e => {
    const isExempt = e === 'exempt';
    setTaxRateRef(e);
    setTaxExemptReason(isExempt ? taxExemptReason : null);
    setDisableTabs(isExempt);
  };

  const onChangeTaxExemptReason = e => {
    setTaxExemptReason(e);
    setDisableTabs(false);
  };

  const onChangeJurisdiction = e => {
    setJurisdictionRef(e);
  };

  const onChangeCostBook = e => {
    setCostBookRef(e);
  };

  const onPricingChange = (id, value) => {
    setCostBook(s => ({ ...s, prices: value }));
  };

  const addOrderCostBook = () => {
    const alreadyExists = costBooks.filter(i => i.id === `ORDER-${order?.id}-${order?.crn}`);

    if (alreadyExists?.length > 0) {
      setCostBookRef(alreadyExists[0]?.crn);
      setCostBook(alreadyExists[0]);
      setOverrideCostBook(true);
    } else {
      setCostBook({
        id: `ORDER-${order?.id}-${order?.crn}`,
        name: `ORDER-${order?.id}`,
        typeId: 'cost-book',
        status: 'ACTIVE',
        orderRef: order?.crn,
        prices: [],
      });

      setOverrideCostBook(true);
    }
  };

  const showFees = !!pricing?.priceSummary?.totalFees && !!+pricing.priceSummary.totalFees;

  const validPricebooks = useMemo(() => {
    const result = [];

    if (!costBooks || !pricing?.products) return result;

    costBooks.forEach(costbook => {
      if (!costbook.prices) return;

      let hasPrice = false;

      pricing.products.forEach(product => {
        const index = costbook.prices.findIndex(price => price.productRef === product.productRef);
        hasPrice = hasPrice || index !== -1;
      });

      if (hasPrice) {
        result.push(costbook);
      }
    });

    return result;
  }, [costBooks, pricing?.products]);

  const translateMessage = useTranslateMessage();

  const resolvedPriceBookComplete = useMemo(() => {
    if (loadingCostBooks) return translateMessage({ stringId: 'loading', defaultMessage: 'Loading...' });

    if (!costBooks || !resolvedCostBookRef) return translateMessage({ stringId: 'none', defaultMessage: 'NONE' });

    const result = costBooks.find(costbook => costbook.crn === resolvedCostBookRef);

    return result ? result.name : translateMessage({ stringId: 'none', defaultMessage: 'NONE' });
  }, [costBooks, resolvedCostBookRef, loadingCostBooks]);

  return (
    <FlexColumn className={cn('pricing', className)}>
      <Spinner spin={pricingBusy}>
        <Row gutter={24} className="form-items">
          <Col>
            <div className="title">
              <Translate stringId="taxBasedOn" defaultMessage="Sales Tax Based On" />
            </div>
            <Select
              id="select-tax-rate"
              className="select-tax-rate"
              defaultValue={pricing?.priceSummary?.taxRate?.taxRateRef}
              value={taxRateRef}
              onChange={onChange}
              disabled={order?.taxRate?.taxRateRef === 'exempt'}
            >
              {taxRates?.map(rate => (
                <Option key={`${rate.crn}-rate`} value={rate.crn}>
                  <Translate stringId={`taxRate__${rate.crn}`} />
                </Option>
              ))}
            </Select>
          </Col>
          {jurisdictionRef && (
            <Col>
              <div className="title">
                <Translate stringId="taxJurisdiction" defaultMessage="Tax Jurisdiction" />
              </div>
              <Select
                id="select-tax-jurisdiction"
                className="select-tax-rate"
                defaultValue={''}
                value={jurisdictionRef}
                onChange={onChangeJurisdiction}
                disabled={order?.taxRate?.taxRateRef === 'exempt' || disableTabs}
              >
                {pricing?.priceSummary?.taxRates?.map(rate => {
                  // The premise of city, state, zip and county doesn't permit localization
                  const { city, state, zip, county } = rate.jurisdiction;
                  const jurisdiction = `${city || ''}, ${state || ''} ${zip || ''} ${
                    county ? `(${county} County)` : ''
                  }`;

                  return (
                    <Option key={`${rate.taxRateRef}-jurisdiction`} value={rate.taxRateRef}>
                      {jurisdiction}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          )}
          {order?.originatorSystemType === 'CONNEX' && (
            <>
              <Col>
                <div className="title">
                  <Translate stringId="resolvedPriceBook" defaultMessage="Resolved Price Book" />
                </div>
                <Select
                  id="select-cost-book"
                  className="select-tax-rate"
                  value={resolvedPriceBookComplete}
                  disabled={true}
                >
                  <Option value={resolvedPriceBookComplete}>{resolvedPriceBookComplete}</Option>
                </Select>
              </Col>
              <HasPricingPermission not permission="edit" supplierParty={pricing?.supplierParty}>
                {taxRateRef === 'exempt' && (
                  <Col>
                    <div className="title">
                      <Translate stringId="taxExemptReason" defaultMessage="Tax-Exempt Reason *" />
                    </div>
                    <Select
                      id="select-tax-exempt-reason"
                      className="select-tax-rate"
                      value={taxExemptReason}
                      onChange={onChangeTaxExemptReason}
                      disable={taxExemptReasonsBusy}
                      required={taxExemptReasonRequired}
                    >
                      {taxExemptReasons?.map(reason => (
                        <Option key={reason.value} value={reason.value}>
                          {reason.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                )}
                <Col>
                  <div className="title">
                    <Translate stringId="overridePriceBook" defaultMessage="Override Price Book" />
                  </div>
                  <Select
                    id="select-cost-book"
                    className="select-tax-rate"
                    value={loadingCostBooks ? 'Loading...' : costBookRef}
                    disabled={true}
                  >
                    {loadingCostBooks && (
                      <Option value="">
                        <Translate stringId="loading" defaultMessage="Loading..." />
                      </Option>
                    )}
                    <Option value={null}>
                      <Translate stringId="none" defaultMessage="NONE" />
                    </Option>
                    {validPricebooks?.map(item => (
                      <Option key={item.crn} value={item.crn}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </HasPricingPermission>
              <HasPricingPermission permission="edit" supplierParty={pricing?.supplierParty}>
                {taxRateRef === 'exempt' && (
                  <Col>
                    <div className="title">
                      <Translate stringId="taxExemptReason" defaultMessage="Tax-Exempt Reason *" />
                    </div>
                    <Select
                      id="select-tax-exempt-reason"
                      className="select-tax-rate"
                      value={taxExemptReason}
                      onChange={onChangeTaxExemptReason}
                      disable={taxExemptReasonsBusy}
                      required={taxExemptReasonRequired}
                    >
                      {taxExemptReasons?.map(reason => (
                        <Option key={reason.value} value={reason.value}>
                          {reason.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                )}
                <Col>
                  <div className="title">
                    <Translate stringId="overridePriceBook" defaultMessage="Override Price Book" />
                  </div>
                  <Select
                    id="select-cost-book"
                    className="select-tax-rate"
                    value={
                      loadingCostBooks
                        ? translateMessage({ stringId: 'loading', defaultMessage: 'Loading...' })
                        : costBookRef
                    }
                    onChange={onChangeCostBook}
                    disabled={loadingCostBooks || disableTabs}
                  >
                    {loadingCostBooks && (
                      <Option value="">
                        <Translate stringId="loading" defaultMessage="Loading..." />
                      </Option>
                    )}
                    <Option value={null}>
                      <Translate stringId="none" defaultMessage="NONE" />
                    </Option>
                    {validPricebooks?.map(item => (
                      <Option key={item.crn} value={item.crn}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col className="action-buttons">
                  <Button
                    className="override-order-costbook"
                    onClick={addOrderCostBook}
                    metricId="core-order-details-override-order-costbook"
                    disabled={disableTabs}
                  >
                    <Translate stringId="overridePricing" defaultMessage="Override Pricing" />
                  </Button>
                </Col>
                <Col className="action-buttons">
                  <Button
                    className="reset-order-costbook"
                    onClick={() => setResetCostBook(true)}
                    metricId="core-order-details-reest-order-costbook"
                    disabled={disableTabs}
                  >
                    <Translate stringId="resetPriceBook" defaultMessage="Reset Price Book" />
                  </Button>
                </Col>
              </HasPricingPermission>
            </>
          )}
        </Row>
        <table>
          <thead>
            <tr>
              <th>
                <Translate stringId="product" defaultMessage="Product" />
              </th>
              <th>
                <Translate stringId="idDescription" defaultMessage="ID / Description" />
              </th>
              {pricing?.priceSummary && (
                <th>
                  <Translate stringId="unitPrice" defaultMessage="Unit Price" />
                </th>
              )}
              {pricing?.priceSummary && (
                <th>
                  <Translate stringId="priceSource" defaultMessage="Price Source" />
                </th>
              )}
              <th>
                <Translate stringId="orderedQuantity" defaultMessage="Ordered Quantity" />
              </th>
              {pricing?.priceSummary && (
                <th className="currency-column">
                  <Translate stringId="extPrice" defaultMessage="Ext. Price" />
                </th>
              )}
              {pricing?.priceSummary && !pricing?.priceSummary?.taxRate?.taxPercentage && (
                <>
                  <th className="currency-column">
                    <Translate
                      stringId={pricing?.priceSummary?.taxRate?.state?.sales ? 'stateTaxWithPercentage' : 'stateTax'}
                      values={{ rate: pricing?.priceSummary?.taxRate?.state?.sales }}
                      defaultMessage="State Tax"
                    />
                  </th>
                  <th className="currency-column">
                    <Translate
                      stringId={pricing?.priceSummary?.taxRate?.county?.sales ? 'countyTaxWithPercentage' : 'countyTax'}
                      values={{ rate: pricing?.priceSummary?.taxRate?.county?.sales }}
                      defaultMessage="County Tax"
                    />
                  </th>
                  <th className="currency-column">
                    <Translate
                      stringId={pricing?.priceSummary?.taxRate?.city?.sales ? 'cityTaxWithPercentage' : 'cityTax'}
                      values={{ rate: pricing?.priceSummary?.taxRate?.city?.sales }}
                      defaultMessage="City Tax"
                    />
                  </th>
                  <th className="currency-column">
                    <Translate
                      stringId={
                        pricing?.priceSummary?.taxRate?.district?.sales ? 'districtTaxWithPercentage' : 'districtTax'
                      }
                      values={{ rate: pricing?.priceSummary?.taxRate?.district?.sales }}
                      defaultMessage="District Tax"
                    />
                  </th>
                </>
              )}
              {pricing?.priceSummary && pricing?.priceSummary?.taxRate?.taxPercentage && (
                <th className="currency-column">
                  <Translate
                    stringId={
                      pricing?.priceSummary?.taxRate?.taxPercentage?.sales ? 'salesTaxWithPercentage' : 'salesTax'
                    }
                    values={{ rate: pricing?.priceSummary?.taxRate?.taxPercentage?.sales }}
                    defaultMessage="Sales Tax"
                  />
                </th>
              )}
              {showFees && (
                <th className="currency-column">
                  <Translate stringId="fees" defaultMessage="Fees" />
                </th>
              )}
              {pricing?.priceSummary && (
                <th className="currency-column">
                  <Translate stringId="totalPrice" defaultMessage="Total Price" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {pricing?.products?.map(p => (
              <tr key={`${p.id}-product`}>
                <td>{p.type}</td>
                <td>
                  {p.id} / {p.name}
                </td>
                {pricing?.priceSummary && (
                  <td>
                    {' '}
                    {p?.minimumLoadCharge ? 'variable' : <Currency value={p.priceSummary?.unitPrice} currency="USD" />}
                  </td>
                )}
                {pricing?.priceSummary && <td>{p?.priceSummary?.priceSource}</td>}
                <td>
                  <Uom uom={{ value: p.quantity, uomCode: p.uomCode }} />
                  {p.category !== 'other'
                    ? ` ${translateMessage({
                        stringId: 'numberOfLoads',
                        defaultMessage: '({loads, number} loads)',
                        values: { loads: p.loadCount },
                      })}`
                    : ''}
                </td>
                {pricing?.priceSummary && (
                  <td className="currency-column">
                    <Currency value={p.priceSummary?.extendedPrice} currency="USD" />
                  </td>
                )}
                {pricing?.priceSummary && !pricing?.priceSummary?.taxRate?.taxPercentage && (
                  <>
                    <td className="currency-column">
                      {pricing.priceSummary?.taxPerProduct && (
                        <Currency value={p.priceSummary?.stateTax} currency="USD" />
                      )}
                    </td>
                    <td className="currency-column">
                      {pricing.priceSummary?.taxPerProduct && (
                        <Currency value={p.priceSummary?.countyTax} currency="USD" />
                      )}
                    </td>
                    <td className="currency-column">
                      {pricing.priceSummary?.taxPerProduct && (
                        <Currency value={p.priceSummary?.cityTax} currency="USD" />
                      )}
                    </td>
                    <td className="currency-column">
                      {pricing.priceSummary?.taxPerProduct && (
                        <Currency value={p.priceSummary?.districtTax} currency="USD" />
                      )}
                    </td>
                  </>
                )}
                {pricing?.priceSummary && pricing?.priceSummary?.taxRate?.taxPercentage && (
                  <td className="currency-column">
                    {pricing.priceSummary?.taxPerProduct && (
                      <Currency value={p.priceSummary?.salesTax} currency="USD" />
                    )}
                  </td>
                )}
                {pricing?.priceSummary && (
                  <td className="currency-column">
                    {pricing.priceSummary?.taxPerProduct && (
                      <Currency value={p.priceSummary?.totalPrice} currency="USD" />
                    )}
                  </td>
                )}
              </tr>
            ))}
            {pricing?.priceSummary && (
              <tr className="totals">
                <td colSpan={5} style={{ textAlign: 'right', paddingRight: '25px', textTransform: 'uppercase' }}>
                  <Translate stringId="totals" defaultMessage="TOTALS" />
                </td>
                <td>
                  <Currency value={pricing?.priceSummary?.subtotalPrice} currency="USD" />
                </td>
                {pricing?.priceSummary?.taxRate?.taxPercentage ? (
                  <td>
                    <Currency value={pricing?.priceSummary?.salesTax} currency="USD" />
                  </td>
                ) : (
                  <>
                    <td>
                      <Currency value={pricing?.priceSummary?.stateTax} currency="USD" />
                    </td>
                    <td>
                      <Currency value={pricing?.priceSummary?.countyTax} currency="USD" />
                    </td>
                    <td>
                      <Currency value={pricing?.priceSummary?.cityTax} currency="USD" />
                    </td>
                    <td>
                      <Currency value={pricing?.priceSummary?.districtTax} currency="USD" />
                    </td>
                  </>
                )}

                {showFees && (
                  <td>
                    <Currency value={pricing.priceSummary.totalFees} currency="USD" />
                  </td>
                )}
                <td>
                  <Currency value={pricing?.priceSummary?.totalPrice} currency="USD" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isDirty && (
          <Has permission="editPricing">
            <Button
              className="btn-reprice"
              onClick={() => reprice(costBookRef)}
              metricId="core-order-detail-reprice"
              disabled={disableTabs}
            >
              <Translate
                stringId="repriceAndSave"
                defaultMessage="Reprice & Save Using Selected Jurisdiction & Price Book"
              />
            </Button>
          </Has>
        )}
      </Spinner>
      <Drawer
        width={drawerWidth}
        title={translateMessage({
          stringId: 'priceBookEditorDrawerTitle',
          defaultMessage: `Custom Order Price Book Editor`,
        })}
        placement="right"
        closable
        onClose={() => setOverrideCostBook(null)}
        visible={overrideCostBook}
        destroyOnClose
      >
        <OrderCostBookPrices
          item={costBook}
          products={pricing?.products}
          onChange={onPricingChange}
          onCancel={onCloseDrawer}
          onSave={onDrawerSave}
        />
      </Drawer>
    </FlexColumn>
  );
};

export default styled(Pricing)`
  ${style}
`;
