import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import style from './style';
import styled from 'styled-components';
import { Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import InputSearch from '../components/input-search/Search';
import { useUserContext } from '../../order/components/user-context/UserContextProvider';
import { buttonClick } from '../../../util/analytics/index';
import { kebabCase } from 'lodash';
import useSetup from '../useSetup';
import { useTranslateMessage } from '../../order/components/localization/useTranslateMessage';

const Styled = styled.div`
  ${style}
`;

const defaultTypes = [
  {
    label: 'Company',
    path: 'supplier',
    icon: 'business',
    stringId: 'setupItems.company',
  },
  {
    label: 'Customers',
    path: 'customers',
    icon: 'storefront',
    stringId: 'setupItems.customers',
  },
  {
    label: 'Projects',
    path: 'projects',
    icon: 'foundation',
    stringId: 'setupItems.projects',
  },
  {
    label: 'Locations',
    path: 'locations',
    icon: 'factory',
    stringId: 'setupItems.locations',
  },
  {
    label: 'Products',
    path: 'products',
    icon: 'category',
    stringId: 'setupItems.products',
  },
  {
    label: 'Product Types',
    path: 'product-type',
    icon: 'inventory_2',
    stringId: 'setupItems.productTypes',
  },
  {
    label: 'Price Adjustments',
    path: 'cost-book-adjustment',
    icon: 'price_change',
    stringId: 'setupItems.priceAdjustments',
  },
  {
    label: 'Price Books',
    path: 'cost-book',
    icon: 'local_offer',
    stringId: 'setupItems.priceBooks',
  },
  {
    label: 'Carriers',
    path: 'carriers',
    icon: 'local_shipping',
    stringId: 'setupItems.carriers',
  },
  {
    label: 'Vehicles',
    path: 'vehicles',
    icon: 'warehouse',
    stringId: 'setupItems.vehicles',
  },
  {
    label: 'Work Types',
    path: 'work-types',
    icon: 'home_work',
    stringId: 'setupItems.workTypes',
  },
  {
    label: 'Payment Methods',
    path: 'payment-methods',
    icon: 'payment',
    stringId: 'setupItems.paymentMethods',
  },
  {
    label: 'Contact Reasons',
    path: 'contact-reason',
    icon: 'content_paste_search',
    stringId: 'setupItems.contactReasons',
  },
  {
    label: 'Contact Types',
    path: 'contact-type',
    icon: 'contact_page',
    stringId: 'setupItems.contactTypes',
  },
  {
    label: 'Shipping Methods',
    path: 'shipping-method',
    icon: 'Group207',
    stringId: 'setupItems.shippingMethods',
  },
  // {
  //   label: 'Spacing Options',
  //   path: 'spacing-option',
  //   icon: 'clock',
  // },
  {
    label: 'Order Statuses',
    path: 'status-code',
    icon: 'OrderIcon',
    stringId: 'setupItems.orderStatuses',
  },
  {
    label: 'Reason Codes',
    path: 'cancel-reason-code',
    icon: 'free_cancellation',
    stringId: 'setupItems.cancelReasons',
  },
  {
    label: 'Vehicle Types',
    path: 'vehicle-type',
    icon: 'vehicle_type',
    stringId: 'setupItems.vehicleTypes',
  },
  // {
  //   label: 'Scales',
  //   path: 'scale',
  //   icon: 'balance-scale-left',
  // },
  // {
  //   label: 'Component Types',
  //   path: 'component-type',
  //   icon: 'vial',
  // },
  {
    label: 'Mix Components',
    path: 'component',
    icon: 'silo',
    stringId: 'setupItems.mixComponents',
  },
  // {
  //   label: 'Behaviors',
  //   path: 'behavior',
  //   icon: 'radiation-alt',
  // },
  {
    label: 'Mix Designs',
    path: 'mix',
    icon: 'science',
    stringId: 'setupItems.mixDesigns',
  },
  {
    label: 'Rules',
    path: 'rule',
    icon: 'rule',
    stringId: 'setupItems.rules',
  },
  {
    label: 'Trackables',
    path: 'trackable',
    icon: 'track_changes',
    stringId: 'setupItems.trackables',
  },
  {
    label: 'Trackable Types',
    path: 'trackable-type',
    icon: 'account_tree',
    stringId: 'setupItems.trackableTypes',
  },
  {
    label: 'Trailers',
    path: 'trailer',
    icon: 'rv_hookup',
    stringId: 'setupItems.trailers',
  },
  {
    label: 'Drivers',
    path: 'driver',
    icon: 'steeringwheel',
    stringId: 'setupItems.drivers',
  },
  {
    label: 'Concrete Classes',
    path: 'concrete-class',
    icon: 'layers',
    stringId: 'setupItems.concreteClasses',
  },
  {
    label: 'Credit Codes',
    path: 'credit-code',
    icon: 'payments',
    stringId: 'setupItems.creditCodes',
  },
];

const Type = ({ label, path, icon }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <div className="type" onClick={() => buttonClick(handleClick, `core-setup-${kebabCase(label)}`)}>
      <i className={`icon connex-v4-icon ${icon}`} />
      <div className="label">{label}</div>
    </div>
  );
};

const Initialize = ({ label, path, icon }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <div className="type" onClick={() => buttonClick(handleClick, `core-setup-${kebabCase(label)}`)}>
      <i className={`icon connex-v4-icon ${icon}`} />
      <div className="label">{label}</div>
    </div>
  );
};

const SetupTypes = ({ types = defaultTypes }) => {
  const user = useUserContext();
  const translateMessage = useTranslateMessage();
  const searchInputRef = useRef(null);
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getSetupItems, setupItems: entities, busy } = useSetup('entity-setup');

  useEffect(() => {
    getSetupItems().then();
    setLoading(false);
  }, [getSetupItems]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const canInitialize = useMemo(() => user?.permissions?.groups?.some(g => ['admin', 'support'].includes(g)), [user]);

  const byLabel = useCallback((a, b) => (a.label < b.label ? -1 : 1), []);
  const byType = useCallback(
    t => {
      const terms = filter?.split(',') ?? [];
      const matches = terms.some(term => t.label.toLowerCase().includes(term.toLowerCase()));

      return terms.length < 1 || matches;
    },
    [filter]
  );

  const translatedTypes = useMemo(() => {
    const translatedList = types.map(t => ({
      ...t,
      label: translateMessage({ stringId: t.stringId, defaultMessage: t.label }),
    }));
    return translatedList;
  }, [types]);

  const list = useMemo(() => {
    if (
      !user.hasPermissions('authorities.enable_rmx-dispatch_setup') &&
      !user.hasPermissions('authorities.enable_setup-items_setup')
    )
      return [];

    // Old Roles only had the enable_rmx-dispatch_setup authority so if they have this authority as true
    // They will have access to all setup items
    if (user.hasPermissions('authorities.enable_rmx-dispatch_setup'))
      return translatedTypes.filter(byType).sort(byLabel);

    //If the enable_setup-items_setup exists its because the role was updated and now uses this new authority
    //If this authority is true we need to filter each setup item to check if the user has access

    const filteredTypes = translatedTypes.filter(type =>
      user.hasPermissionNoAdmin(`authorities.enable_setup-items_setup_${type.path}`)
    );

    return filteredTypes.filter(byType).sort(byLabel);
  }, [byType, byLabel, translatedTypes, user]);

  if (loading || busy) {
    return null;
  }

  if (entities.length === 0 && !canInitialize) {
    return (
      <Styled>
        <Alert showIcon type={'error'} message={'Initialization Needed'} description={'Please contact support.'} />
      </Styled>
    );
  }

  return (
    <Styled>
      <InputSearch innerRef={searchInputRef} onChange={setFilter} />
      <div className="types">
        {entities.length > 0 &&
          list?.map(t => <Type key={t.path} label={t.label} path={t.path} icon={t.icon} customSize={t.customSize} />)}
        {canInitialize && (
          <Initialize
            label={translateMessage({ stringId: 'setupItems.initialize', defaultMessage: 'Initialize' })}
            path="initialize"
            icon="connex-v4-icon token"
          />
        )}
      </div>
    </Styled>
  );
};

export default SetupTypes;
