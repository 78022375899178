import { css } from 'styled-components';

export default css`
  width: 100%;
  height: 100%;
  .ant-tabs {
    height: 100%;
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        // display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
      }
    }
  }
  .ant-btn,
  .option-flag {
    margin-top: 10px;
  }

  .form-group-label {
    margin-bottom: 5px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .po-container {
    display: flex;
    justify-content: flex-end;
    gap: 0;
    padding: 0;
    margin-top: 1rem;
    
    & > div {
      max-width: min(40%, 190px);
    }

    .ant-checkbox+span {
    }
  }
  .two-rows-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
      align-items: center;

    .left {
      grid-column: 1;
    }

    .right {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: end;
      position: relative;
      bottom: 5px;
    }
  }
`;
