import { css } from 'styled-components';

export default css`
  width: 100%;
  height: 100%;
  .ant-tabs {
    height: 100%;
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        // display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
      }
    }
  }
  .ant-btn,
  .option-flag {
    margin-top: 10px;
  }

  .control-row {
    display: flex;

    .control-col-6 {
      width: calc(50% - 5px);

      .field-container {
        display: inline-flex;
        margin: 5px 0;

        .label-spinner {
          display: flex;
          margin-right: 5px;
        }

        .ant-input[type='color'] {
          width: 64px;
        }
      }
    }

    .control-col-6 ~ .control-col-6 {
      margin-left: 10px;
    }
  }
`;
